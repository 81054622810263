<template>
  <div class="user-assigned-lotteries">
    <a-row type="flex" justify="space-between">
      <a-col :lg="10" :md="10" :xs="24">
        <h2 class="list-title">
          <a-icon type="folder" class="list-icon" />
          <span>{{ labels.title }}</span>
        </h2>
        <p class="list-subtitle">{{ labels.subtitle }}</p>
      </a-col>
      <a-col :lg="14" :md="14" :xs="24">
        <a-row
          type="flex"
          justify="end"
          :gutter="[8, 8]"
          :style="{
            alignItems: 'flex-end',
            marginTop: '25px',
            marginBottom: '10px',
            textAlign: 'right',
          }"
        >
          <a-col :xs="8" :sm="4" :md="4" :style="{ textAlign: 'right' }">
            <a-button type="primary" icon="plus" @click="handleOpenModal">
              {{ labels.add }}
            </a-button>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <StandardTable
          ref="standardTable"
          :loading="loading"
          :data="data"
          :columns="columns"
          :locale="locale"
          :handleShowTotal="false"
          :scroll="{ x: true }"
          :paginate="false"
          :customRow="handleCustomRow"
          :rowKey="(record) => record?.lottery?.id"
        />
      </a-col>
    </a-row>
    <a-modal
      v-model="openModal"
      :title="form.index !== null ? labels.modal.edit : labels.modal.add"
      :okText="form.id ? labels.modal.updateText : labels.modal.okText"
      :cancelText="labels.modal.cancelText"
      @ok="handleSave"
      @cancel="handleCancel"
      :width="this.screenWidth >= 768 ? 720 : '100%'"
    >
      <ValidationObserver v-if="openModal" ref="assignLotteryObserver">
        <a-row type="flex" justify="start" :gutter="[16, 16]">
          <a-col :span="24">
            <SelectPagination
              ref="lotterySelect"
              v-model="form.lottery"
              :fetchService="handleFetchLotteries"
              :placeholder="labels.form.lotteries.placeholder"
              :labelKey="'name'"
              :valueKey="'id'"
              :disabledDefaultFetchOptions="false"
              :label="labels.form.lotteries.label"
              :allowClear="true"
              :rules="'required'"
              :customStyle="{ width: '100%' }"
              v-bind="{
                ...(form?.index === null ? { mode: 'multiple' } : {}),
              }"
            />
            <a-button
              v-if="form?.index === null"
              type="link"
              class="all-button"
              @click="() => $refs.lotterySelect.selectAll()"
              block
            >
              <font-awesome-icon
                class="anticon"
                :icon="['fas', 'check-double']"
              />
              {{ labels.form.lotteries.selectAll }}
            </a-button>
          </a-col>
        </a-row>
        <a-row type="flex" justify="start" :gutter="[16, 16]">
          <a-col :xs="24" :sm="24" :md="12">
            <DatePicker
              v-model="form.start_date"
              :show-time="true"
              :vid="labels.form.startDate.label"
              :name="labels.form.startDate.label"
              :formitem="{ label: labels.form.startDate.label }"
              :placeholder="labels.form.startDate.placeholder"
              :format="dateFormat"
              rules="required"
            />
          </a-col>
          <a-col :xs="24" :sm="24" :md="12">
            <SwitchInput
              v-model.lazy="form.status"
              :label="labels.form.status"
              :align="'right'"
            />
          </a-col>
        </a-row>

        <a-row type="flex" justify="start" :gutter="24">
          <a-col :span="24">
            <h2>
              <font-awesome-icon
                :icon="['far', 'money-bill-1']"
                :class="'anticon'"
              />
              {{ labels.form.rewards }}
            </h2>
          </a-col>
        </a-row>
        <a-row type="flex" justify="start" :gutter="24">
          <a-col :xs="24" :sm="24" :md="6">
            <h2>{{ labels.form.quiniela }}</h2>
          </a-col>
          <a-col :xs="24" :sm="24" :md="6">
            <Input
              type="number"
              v-model="form.quiniela_first"
              :label="labels.form.first.label"
              :placeholder="labels.form.first.placeholder"
              :rules="'required|decimal:2|min:0'"
              :allowClear="true"
              :addon-before="labels.form.first.label"
              :showLabel="false"
            />
          </a-col>
          <a-col :xs="24" :sm="24" :md="6">
            <Input
              type="number"
              v-model="form.quiniela_second"
              :label="labels.form.second.label"
              :placeholder="labels.form.second.placeholder"
              :rules="'required|decimal:2|min:0'"
              :allowClear="true"
              :addon-before="labels.form.second.label"
              :showLabel="false"
            />
          </a-col>
          <a-col :xs="24" :sm="24" :md="6">
            <Input
              type="number"
              v-model="form.quiniela_third"
              :label="labels.form.third.label"
              :placeholder="labels.form.third.placeholder"
              :rules="'required|decimal:2|min:0'"
              :allowClear="true"
              :addon-before="labels.form.third.label"
              :showLabel="false"
            />
          </a-col>
        </a-row>
        <a-row type="flex" justify="start" :gutter="24">
          <a-col :xs="24" :sm="24" :md="6">
            <h2>{{ labels.form.pale }}</h2>
          </a-col>
          <a-col :xs="24" :sm="24" :md="6">
            <Input
              type="number"
              v-model="form.pale_first"
              :label="labels.form.first.label"
              :placeholder="labels.form.first.placeholder"
              :rules="'required|decimal:2|min:0'"
              :allowClear="true"
              :addon-before="labels.form.first.label"
              :showLabel="false"
            />
          </a-col>
          <a-col :xs="24" :sm="24" :md="6">
            <Input
              type="number"
              v-model="form.pale_second"
              :label="labels.form.second.label"
              :placeholder="labels.form.second.placeholder"
              :rules="'required|decimal:2|min:0'"
              :allowClear="true"
              :addon-before="labels.form.second.label"
              :showLabel="false"
            />
          </a-col>
        </a-row>
        <a-row type="flex" justify="start" :gutter="24">
          <a-col :xs="24" :sm="24" :md="6">
            <h2>{{ labels.form.tripleta }}</h2>
          </a-col>
          <a-col :xs="24" :sm="24" :md="6">
            <Input
              type="number"
              v-model="form.tripleta_first"
              :label="labels.form.first.label"
              :placeholder="labels.form.first.placeholder"
              :rules="'required|decimal:2|min:0'"
              :allowClear="true"
              :addon-before="labels.form.first.label"
              :showLabel="false"
            />
          </a-col>
          <a-col :xs="24" :sm="24" :md="6">
            <Input
              type="number"
              v-model="form.tripleta_second"
              :label="labels.form.second.label"
              :placeholder="labels.form.second.placeholder"
              :rules="'required|decimal:2|min:0'"
              :allowClear="true"
              :addon-before="labels.form.second.label"
              :showLabel="false"
            />
          </a-col>
        </a-row>
        <a-row type="flex" justify="start" :gutter="24">
          <a-col :xs="24" :sm="24" :md="6">
            <h2>{{ labels.form.superpale }}</h2>
          </a-col>
          <a-col :xs="24" :sm="24" :md="6">
            <Input
              type="number"
              v-model="form.superpale_first"
              :label="labels.form.first.label"
              :placeholder="labels.form.first.placeholder"
              :rules="'required|decimal:2|min:0'"
              :allowClear="true"
              :addon-before="labels.form.first.label"
              :showLabel="false"
            />
          </a-col>
        </a-row>
      </ValidationObserver>
    </a-modal>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import _ from "lodash";
import { ValidationObserver } from "vee-validate";
import { DATE_FORMAT } from "@/utils/utils";
import accounting from "accounting";
import StandardTable from "@/components/core/table/StandardTable.vue";
import SelectPagination from "@/components/core/SelectPagination/index.vue";
import Input from "@/components/core/VeeValidateForm/Input.vue";
import SwitchInput from "@/components/core/VeeValidateForm/Switch.vue";
import DatePicker from "@/components/core/VeeValidateForm/DatePicker.vue";

const defaultForm = {
  index: null,
  id: null,
  start_date: null,
  lottery: null,
  status: true,
  quiniela_first: null,
  quiniela_second: null,
  quiniela_third: null,
  pale_first: null,
  pale_second: null,
  tripleta_first: null,
  tripleta_second: null,
  superpale_first: null,
};

export default {
  name: "AssignedLotteries",
  components: {
    StandardTable,
    ValidationObserver,
    SelectPagination,
    Input,
    SwitchInput,
    DatePicker,
  },
  props: {
    labels: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dataSource: {
      type: Array,
      required: true,
      default: () => [],
    },
    onlyUnassignedTo: {
      type: Number,
      default: null,
    },
  },
  emits: ["update:modelValue", "change", "delete"],
  data() {
    return {
      data: [],
      form: _.cloneDeep(defaultForm),
      openModal: false,
      activeRow: null,
    };
  },
  filters: {
    ucwords(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  computed: {
    ...mapState("layout", ["screenWidth"]),
    isMobile() {
      return this.screenWidth < 600;
    },
    locale() {
      return {
        emptyText: this.labels.table.emptyText,
      };
    },
    dateFormat() {
      return "DD/MM/YYYY hh:mm:ss";
    },
    serverDateFormat() {
      return "YYYY-MM-DD HH:mm:ss";
    },
    columns() {
      return [
        {
          title: this.labels.table.columns.startDate,
          dataIndex: "start_date",
          key: "start_date",
          width: "15%",
          hidden: this.isMobile,
          customRender: (text) => {
            return this.$moment(text).format("DD/MM/YYYY H:mm:ss A");
          },
        },
        {
          title: this.labels.table.columns.lottery,
          dataIndex: "lottery",
          key: "lottery",
          width: "15%",
          hidden: this.isMobile,
          customRender: (text) => {
            return text.name || "";
          },
        },
        {
          title: this.labels.table.columns.quinielaFirst,
          dataIndex: "quiniela_first",
          key: "quiniela_first",
          width: "12%",
          align: "right",
          hidden: this.isMobile,
          customRender: (text) => {
            return accounting.formatNumber(text || 0, 0, ",", ".");
          },
        },
        {
          title: this.labels.table.columns.paleFirst,
          dataIndex: "pale_first",
          key: "pale_first",
          width: "12%",
          align: "right",
          hidden: this.isMobile,
          customRender: (text) => {
            return accounting.formatNumber(text || 0, 0, ",", ".");
          },
        },
        {
          title: this.labels.table.columns.tripletaFirst,
          dataIndex: "tripleta_first",
          key: "tripleta_first",
          width: "12%",
          align: "right",
          hidden: this.isMobile,
          customRender: (text) => {
            return accounting.formatNumber(text || 0, 0, ",", ".");
          },
        },
        {
          title: this.labels.table.columns.superpaleFirst,
          dataIndex: "superpale_first",
          key: "superpale_first",
          width: "12%",
          align: "right",
          hidden: this.isMobile,
          customRender: (text) => {
            return accounting.formatNumber(text || 0, 0, ",", ".");
          },
        },
        {
          title: this.labels.table.columns.status.title,
          dataIndex: "status",
          key: "status",
          width: "12%",
          align: "center",
          hidden: this.isMobile,
          customRender: (text) => {
            return (
              <a-tag color={text ? "green" : "red"}>
                {text
                  ? this.labels.table.columns.status.active
                  : this.labels.table.columns.status.inactive}
              </a-tag>
            );
          },
        },
        {
          title: this.labels.table.columns.actions.title,
          dataIndex: "actions",
          key: "actions",
          width: "10%",
          align: "center",
          hidden: this.isMobile,
          customRender: (_, record, index) => {
            return (
              <div class="action-buttons">
                <a-button
                  type="link"
                  icon="info-circle"
                  class="action-button-view"
                  title={this.labels.table.columns.actions.moreInfo}
                  onClick={() => this.handleShowMoreInfo(record)}
                />
                <a-button
                  type="link"
                  icon="edit"
                  class="action-button-edit"
                  title={this.labels.table.columns.actions.edit}
                  onClick={() => this.handleEdit(record, index)}
                />
                <a-button
                  type="link"
                  icon="delete"
                  class="action-button-delete"
                  title={this.labels.table.columns.actions.delete}
                  onClick={() =>
                    record?.id
                      ? this.confirmDelete(index)
                      : this.handleDelete(index)
                  }
                />
              </div>
            );
          },
        },
        {
          title: this.labels.table.columns.assignations,
          dataIndex: "assignations",
          key: "assignations",
          width: "100%",
          hidden: !this.isMobile,
          customRender: (_, record, index) => {
            // Card item design
            const color = record?.status ? "#108ee9" : "#ff5500";
            return (
              <div class="list-item">
                <div class="list-item-head">
                  <div
                    class="list-item-head-header"
                    style={{ borderColor: color }}
                  >
                    <span>
                      {this.$options.filters.ucwords(
                        this.$moment(record.start_date).format(
                          DATE_FORMAT.MOMENT_DAY_MONTH
                        )
                      )}
                    </span>
                    <small>
                      {this.$moment(record.start_date)
                        .format(DATE_FORMAT.MOMENT_TIME)
                        .toUpperCase()}
                    </small>
                  </div>
                  <div class="list-item-head-content">
                    <div class="list-item-head-content-lottery">
                      <span>{record.lottery.name}</span>
                    </div>
                    <div class="list-item-head-content-status">
                      <a-tag color={color}>
                        {record?.status
                          ? this.labels.table.columns.status.active
                          : this.labels.table.columns.status.inactive}
                      </a-tag>
                    </div>
                  </div>
                </div>
                {this.activeRow === index && (
                  <div class="list-item-actions">
                    <a-button-group>
                      <a-button
                        type="primary"
                        class="more-info-button"
                        title={this.labels.table.columns.actions.moreInfo}
                        ghost
                        onClick={(event) =>
                          this.handleShowMoreInfo(record, event)
                        }
                      >
                        {this.labels.table.columns.actions.moreInfo}
                      </a-button>
                      <a-button
                        class="edit-button ant-btn ant-btn-primary"
                        title={this.labels.table.columns.actions.edit}
                        onClick={() => this.handleEdit(record, index)}
                        type="primary"
                      >
                        {this.labels.table.columns.actions.edit}
                      </a-button>
                      <a-button
                        class="delete-button ant-btn ant-btn-danger"
                        title={this.labels.table.columns.actions.delete}
                        onClick={() =>
                          record?.id
                            ? this.confirmDelete(index)
                            : this.handleDelete(index)
                        }
                        type="danger"
                      >
                        {this.labels.table.columns.actions.delete}
                      </a-button>
                    </a-button-group>
                  </div>
                )}
              </div>
            );
          },
        },
      ].filter((column) => !column.hidden);
    },
  },
  methods: {
    ...mapActions("lotteries", ["fetchLotteries"]),
    handleFetchLotteries(request) {
      return this.fetchLotteries({
        ...request,
        ...(this.onlyUnassignedTo && !this.form?.id
          ? { onlyUnassignedTo: this.onlyUnassignedTo }
          : {}),
        paginate: false,
      });
    },
    handleCustomRow(_, index) {
      return {
        on: {
          click: () => {
            this.activeRow = this.activeRow === index ? null : index;
          },
        },
      };
    },
    resetForm() {
      this.form = _.cloneDeep(defaultForm);
      this.$refs.assignLotteryObserver.reset();
    },
    handleEdit(record, index) {
      this.form = _.cloneDeep({ ...record, index });
      this.openModal = true;
    },
    confirmDelete(index) {
      this.$confirm({
        title: this.labels.table.confirmDelete.title,
        content: this.labels.table.confirmDelete.content,
        okText: this.labels.table.confirmDelete.okText,
        okType: "danger",
        cancelText: this.labels.table.confirmDelete.cancelText,
        onOk: () => {
          this.handleDelete(index);
        },
      });
    },
    async handleSave() {
      try {
        const valid = await this.$refs.assignLotteryObserver.validate();
        if (!valid) return;

        if (this.form.index !== null) {
          this.data[this.form.index] = _.cloneDeep(this.form);
        } else {
          if (_.isArray(this.form.lottery)) {
            this.form.lottery.forEach((lottery) => {
              this.data.push({
                ..._.cloneDeep(this.form),
                lottery,
              });
            });
          } else {
            this.data.push(_.cloneDeep(this.form));
          }
        }
        this.handleUpdate();
        this.openModal = false;
        this.resetForm();
      } catch (error) {
        console.error(error);
      }
    },
    handleShowMoreInfo(record, event) {
      event?.stopPropagation();
      this.$info({
        title: this.labels.moreInfo.title.replace(
          "{name}",
          record?.lottery?.name
        ),
        content: (
          <div>
            <p>
              <strong>{this.labels.moreInfo.startDate}:</strong>{" "}
              {this.$moment(record?.date).format(DATE_FORMAT.MOMENT_DATE_TIME)}
            </p>
            <p>
              <strong>{this.labels.moreInfo.status}:</strong>{" "}
              {record?.status
                ? this.labels.table.columns.status.active
                : this.labels.table.columns.status.inactive}
            </p>
            <a-divider class="divider" />
            <p>
              <strong>{this.labels.moreInfo.quinielaFirst}:</strong>{" "}
              {accounting.formatNumber(
                record?.quiniela_first || 0,
                2,
                ",",
                "."
              )}
            </p>
            <p>
              <strong>{this.labels.moreInfo.quinielaSecond}:</strong>{" "}
              {accounting.formatNumber(
                record?.quiniela_second || 0,
                2,
                ",",
                "."
              )}
            </p>
            <p>
              <strong>{this.labels.moreInfo.quinielaThird}:</strong>{" "}
              {accounting.formatNumber(
                record?.quiniela_third || 0,
                2,
                ",",
                "."
              )}
            </p>
            <a-divider class="divider" />
            <p>
              <strong>{this.labels.moreInfo.paleFirst}:</strong>{" "}
              {accounting.formatNumber(record?.pale_first || 0, 2, ",", ".")}
            </p>
            <p>
              <strong>{this.labels.moreInfo.paleSecond}:</strong>{" "}
              {accounting.formatNumber(record?.pale_second || 0, 2, ",", ".")}
            </p>
            <a-divider class="divider" />
            <p>
              <strong>{this.labels.moreInfo.tripletaFirst}:</strong>{" "}
              {accounting.formatNumber(
                record?.tripleta_first || 0,
                2,
                ",",
                "."
              )}
            </p>
            <p>
              <strong>{this.labels.moreInfo.tripletaSecond}:</strong>{" "}
              {accounting.formatNumber(
                record?.tripleta_second || 0,
                2,
                ",",
                "."
              )}
            </p>
            <a-divider class="divider" />
            <p>
              <strong>{this.labels.moreInfo.superpaleFirst}:</strong>{" "}
              {accounting.formatNumber(
                record?.superpale_first || 0,
                2,
                ",",
                "."
              )}
            </p>
          </div>
        ),
      });
    },
    handleCancel() {
      this.resetForm();
      this.openModal = false;
    },
    handleOpenModal() {
      this.openModal = true;
    },
    handleDelete(index) {
      const found = _.cloneDeep(this.data[index]);
      this.$emit("delete", { index, record: found });
      // REMOVE FROM DATA
      this.data.splice(index, 1);
      this.handleUpdate();
    },
    handleUpdate() {
      this.$emit("update:modelValue", this.data);
      this.$emit("change", this.data);
    },
  },
  watch: {
    dataSource: {
      handler() {
        if (this.dataSource.length === 0) this.data = [];
        else this.data = _.cloneDeep(this.dataSource);
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variable";
.user-assigned-lotteries {
  .list {
    &-title {
      margin-bottom: 10px;
      .list-icon {
        margin-right: 10px;
      }
    }
    &-subtitle {
      margin-bottom: 10px;
    }
  }
  .add-button {
    color: color(primary);
    border-color: color(primary);
    margin-left: 10px;
  }
  .action-buttons {
    display: flex;
    justify-content: space-between;
    .action-button-view {
      color: color(primary);
    }
    .action-button-cancel {
      color: color(danger);
    }
  }

  .list-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    &-head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 6px;
      &-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2px;
        max-width: 22%;
        min-width: 22%;
        border-right: 4px solid color(border);
        .success {
          color: color(success);
        }
        .warning {
          color: color(warning);
        }
        .danger {
          color: color(danger);
        }
        span {
          font-size: 14px;
          font-weight: 600;
        }
        small {
          font-size: 12px;
          font-weight: 400;
          padding-right: 4px;
        }
      }
      &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2px;
        max-width: 78%;
        min-width: 78%;
        overflow: hidden;
        &-lottery {
          span {
            font-size: 14px;
            font-weight: 600;
            text-overflow: ellipsis;
          }
        }
      }
    }
    &-actions {
      width: 100%;
      margin-top: 10px;
      .ant-btn-group {
        width: 100%;
        .more-info-button,
        .delete-button,
        .edit-button {
          width: 100%;
        }
      }
    }
  }
}
</style>
